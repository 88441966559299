import React from 'react';
import './Buttons.css';
import twitterIcon from './twitter.jpg';
import moonShotIcon from './moonshot.png';
import telegramIcon from './telegram.png';
import gif from './7ff.gif';

const ButtonContainer = () => {
  return (
    <div className="navigation-container">
      <div className="logo-container">
        <img src={gif} alt="Logo" />
      </div>
      <div className="button-container">
        <button className="shared-button">
          <a href="https://dexscreener.com/solana/fjfqm8vnujrfxvpta6cmcrejdfhq8bvzsjppgme18zn2" target="_blank" rel="noopener noreferrer">
            <img src={moonShotIcon} alt="Pump.fun" className="button-icon" />
            <span className="button-text">Moonshot</span>
          </a>
        </button>
        <button className="shared-button">
          <a href="https://x.com/McNuggiesOnSol" target="_blank" rel="noopener noreferrer">
            <img src={twitterIcon} alt="Twitter" className="button-icon" />
            <span className="button-text">Twitter</span>
          </a>
        </button>
        <button className="shared-button">
          <a href="https://t.me/McNuggiesMoonshot" target="_blank" rel="noopener noreferrer">
            <img src={telegramIcon} alt="Telegram" className="button-icon" />
            <span className="button-text">Telegram</span>
          </a>
        </button>
      </div>
      <div className="logo-container">
        <img src={gif} alt="Logo" />
      </div>
    </div>
  );
};

export default ButtonContainer;