import React, { useEffect, useRef } from 'react';
import './RotatingBanner.css';

const RotatingBanner = ({ bannerText }) => {
  const bannerRef = useRef(null);
  const bannerTextContent = bannerText;
  const requestIdRef = useRef(null);

  useEffect(() => {
    const banner = bannerRef.current;
    const bannerWidth = banner.offsetWidth;
    let translateX = 0;

    const animation = () => {
      translateX = (translateX - 1) % bannerWidth;
      banner.style.transform = `translateX(${translateX}px)`;
      requestIdRef.current = requestAnimationFrame(animation);
    };

    const startAnimation = () => {
      requestIdRef.current = requestAnimationFrame(animation);
    };

    startAnimation();

    return () => {
      cancelAnimationFrame(requestIdRef.current);
    };
  }, []);

  return (
    <div className="rotating-banner">
      <ul ref={bannerRef} className="banner-list">
        {[...Array(5)].map((_, index) => (
          <li key={index} aria-hidden={index !== 0} className="banner-item">
            <p className="banner-text">{bannerTextContent}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RotatingBanner;