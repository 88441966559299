import './App.css';
import ButtonContainer from "./Buttons(Fortnite)/Buttons";
import RotatingBanner from "./RotatingBanner/RotatingBanner";
import ImageWithText from "./ImageWithText/ImageWithText";
import ImageWithText2 from "./ImageWithText/ImageWithText2";

function App() {
  const bannerTexts = ['$NUG YOUR FAVORITE SENDORR $NUG', 'EMBRACE THICCNESS ON SOLANA'];

  return (
    <div className="App">
      <ButtonContainer />
      <RotatingBanner bannerText={bannerTexts[0]} />
      <ImageWithText />
      <RotatingBanner bannerText={bannerTexts[1]} />
      <ImageWithText2 />
    </div>
  );
}

export default App;