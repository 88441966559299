import React from 'react';
import './ImageWithText.css';
import mcNuggiesImage2 from './mcnuggies2.png';

const ImageWithText2 = () => {
  return (
    <div className="image-text-container2">
      <div className="image-container">
        <img src={mcNuggiesImage2} alt="McNuggies" />
      </div>
      <div className="text-container">
        <h2>How to Score Your $NUG Stash</h2>
        <p>
        

Here's how the degen nugfesters are stacking their $NUG:

Head to Moonshot and connect your crypto wallet. Now Look for $NUG
Enter a buy amount that'll make your nuggets tingle. We recommend going FULL SEND for maximum nugoid pleasure.
Double-check the slippage tolerance is cranked up for that supreme nugbondage potential.
Finalize the nugget swap and get ready for an euphoric nugget experience.
        </p>
      </div>
    </div>
  );
};

export default ImageWithText2;