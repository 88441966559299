import React from 'react';
import './ImageWithText.css';
import mcNuggiesImage from './mcnuggies.png';

const ImageWithText = () => {
  return (
    <div className="image-text-container">
      <div className="image-container">
        <img src={mcNuggiesImage} alt="McNuggies" />
      </div>
      <div className="text-container">
        <h2>$NUG</h2>
        <p>
        Degenerates, prepare to embrace the thiccest meme on the blockchain. Introducing McNuggies - a coin so hot, so provocative, it'll make you crave those nuggies day and night.
        </p>
      </div>
    </div>
  );
};

export default ImageWithText;